<template>
  <div class="star-send-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <div class="card-title">星光发放</div>
      <div class="clear-box">
        <div class="label">自动清空时间：</div>
        <div class="val">
          {{ clear_time }}
        </div>

        <div class="label" style="margin-left: 20px">星光更新时间：</div>
        <div class="val">
          {{ send_time }}
        </div>

        <div class="label" style="margin-left: 20px">
          星光数量
          <a-tooltip>
            <template slot="title">
              一般在星光更新时间后，进行星光分配
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
          ：
        </div>
        <div class="val">
          {{ account }}
        </div>
      </div>

      <div class="search-box"   @keypress="keyPress($event)"   >
        <div class="time-box">
          <div class="time-title">流水大于：</div>
          <a-input-number
            v-model="search"
            placeholder="请输入流水数"
            :maxLength="10"
            width="200px"
            allowClear
            :min="0"
            class="input-search"
          />

          <div class="button-box">
            <le-button width="70px" fontSize="16px" @btnClick="searchClick()" />

            <le-button
              width="70px"
              fontSize="16px"
              txt="重置"
              style="margin-left: 10px"
              background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
              @btnClick="search = ''"
            />
          </div>
        </div>
      </div>

      <div class="tips-box">
        统计时间：{{ dateData.start }}~{{ dateData.end }}
      </div>

      <div class="table-box">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          :pagination="pageOptions"
          @change="handleTableChange"
          size="middle"
        >
          <span slot="customTitle">流水大于{{ searchEnd }}的主播数</span>
          <div slot="nameUid" slot-scope="row">
            <div class="name-uid">
              <div>{{ row.uname }}</div>
              <div>{{ row.uid }}</div>
            </div>
          </div>

          <div slot="starlight" slot-scope="row">
            {{ row.starlight }}
          </div>
          <span slot="action" slot-scope="row">
            <a @click="clickEditYe(row)"  v-if="btn.isEdit"  >编辑余额</a>
            <a-divider type="vertical"  v-if="btn.isEdit"  />
            <a
              @click="
                visible = true;
                rows = row;
              "
              v-if="btn.isList"
              >主播列表</a
            >
            <a-divider type="vertical" v-if="btn.isList" />
            <a
              @click="
                visibleSend = true;
                rows = row;
              "
              v-if="btn.isLog"
              >使用记录</a
            >
          </span>
        </a-table>
      </div>
    </le-card>

    <popAnchor
      v-if="visible"
      :visible="visible"
      :row="rows.now_professional_anchor_list"
      @handleOk="editTimeOk()"
      @handleCancel="editTimeOk()"
    />
    <!-- <popEditSend
      v-if="visibleSend"
      :visible="visibleSend"
      :info="sendObj"
      @handleOk="editSendTimeOk()"
      @handleCancel="editSendTimeOk()"
    /> -->

    <popLog
      v-if="visibleSend"
      :visible="visibleSend"
      :info="sendObj"
      :row="rows"
      @handleOk="editSendTimeOk()"
      @handleCancel="editSendTimeOk()"
    />

    <popEditYe
      :row="rows"
      v-if="visibleYe"
      :visible="visibleYe"
      @handleOk="editYeOk()"
      @handleCancel="visibleYe = false"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "公会",
    dataIndex: "name",
  },
  {
    title: "经纪人",
    scopedSlots: { customRender: "nameUid" },
  },
  {
    title: "流水大于目标查询的主播数",
    // slots: { title: 'customTitle' },
    align: "center",
    dataIndex: "now_professional_anchor",
    sorter: (a, b) => a.now_professional_anchor > b.now_professional_anchor?1:-1,
  },
  {
    title: "应发星光(参考)",
    align: "center",
    dataIndex: "calc_star",
    sorter: (a, b) => a.calc_star - b.calc_star,
  },
  {
    title: "上周发放星光",
    align: "center",
    dataIndex: "last_week_give_out",
  },
  {
    title: "上周使用星光",
    align: "center",
    dataIndex: "last_week_consume",
  },
  {
    title: "余额",
    align: "center",
    dataIndex: "starlight",
    sorter: (a, b) => a.starlight - b.starlight,
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 260,
    scopedSlots: { customRender: "action" },
  },
];
import { MixinList } from "../mixin";
import {
  popEditSend,
  popEditYe,
  popAnchor,
  popLog,
} from "@/components/star/guild";
export default {
  name: "star-send",
  components: {
    // popEditSend,
    popEditYe,
    popAnchor,
    popLog,
  },
  data() {
    return {
      columns,
      dataUrl: "v2/starShine/broker",
      delUrl: "admin/layout/del",
      visible: false,
      visibleSend: false,
      visibleYe: false,
      clearObj: {},
      sendObj: {},
      rows: {},
    };
  },
  computed: {},
  mixins: [MixinList],
  created(){
    let btn=window.localStorage.getItem('whiteBtns')
      if(btn){
        let btns=btn.split(',');
        if(btns.includes('star-sends-edit')){
          this.btn.isEdit = true;
        }
        if(btns.includes('star-sends-list')){
          this.btn.isList = true;
        }
        if(btns.includes('star-sends-log')){
          this.btn.isLog = true;
        }
      }
      console.log('btn',  this.btn)
  },
  mounted() {
    // this.get_clear_time(); //获取自动清空时间
    // this.get_send_time(); //获取自动发放的时间
  },
  filters: {
    week(val) {
      if (!val) {
        return "--";
      }
      switch (val) {
        case 1:
          return "一";
          break;
        case 2:
          return "二";
          break;
        case 3:
          return "三";
          break;
        case 4:
          return "四";
          break;
        case 5:
          return "五";
          break;
        case 6:
          return "六";
          break;
        case 7:
          return "日";
          break;
      }
    },
  },
  methods: {
    //监听回车键
    keyPress(e){
      if(e.charCode==13){
        this.searchClick()
      }
    },
    searchClick() {
      console.log(this.search);
      if (!this.search) {
        this.$message.error("请填入搜索条件");
        return false;
      }
      let param = {};
      if (this.search) {
        this.queryParam.page = 1;
        this.queryParam.flow = this.search;
        this.getData();
      }
    },
    editTimeOk() {
      this.visible = false;
      this.get_clear_time();
    },
    editSendTimeOk() {
      this.visibleSend = false;
      this.get_send_time();
    },

    editYeOk() {
      this.visibleYe = false;
      this.getData();
    },
    clickEdit() {
      this.visible = true;
    },

    clickSendEdit() {
      this.visibleSend = true;
    },

    clickEditYe(row) {
      this.rows = row;
      this.visibleYe = true;
    },
    clickSend() {},
    auto_send() {
      this.$api.post(`v1/user/starlight/auto_send`, {}).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    get_clear_time() {
      this.$api.get(`v1/user/starlight/get_clear_time`).then((res) => {
        if (res.code == 200) {
          this.clearObj = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    get_send_time() {
      this.$api.get(`v1/user/starlight/get_send_time`).then((res) => {
        if (res.code == 200) {
          this.sendObj = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.star-send-container {
  width: 100%;
  .title {
    margin-left: 30px;
    padding-top: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .clear-box {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-top: 10px;
    .label {
      font-size: 16px;
      color: #333333;
    }
    .val {
      font-size: 14px;
      color: #008df0;
    }
    .btn-edit {
      margin-left: 20px;
    }
    .btn-lk-send {
      margin-left: 20px;
    }
  }
  .table-box {
    margin: 30px;
  }
}

.tips-box {
  margin-top: 20px;
  margin-left: 30px;
  font-size: 12px;
  color: #aaa;
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .time-box {
    display: flex;
    align-items: center;
    .time-title {
      margin-left: 30px;
      font-size: 16px;
      color: #333333;
    }
    .input-search {
      width: 200px;
      margin-left: 10px;
    }
  }
  .button-box {
    margin-left: 30px;
    display: flex;
  }
}

.name-box {
  display: flex;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@media screen and(max-width: 750px) {
  .star-send-container {
    width: 100%;
    .clear-box {
      margin-left: 20px;
      margin-top: 10px;
      flex-wrap: wrap;
      .label {
        font-size: 14px;
        margin-left: 0px;
      }
      .val {
        font-size: 14px;
      }
      .btn-edit {
        margin-left: 5px;
      }
      .btn-edit-bottom {
        margin-top: 5px;
      }
      .btn-lk-send {
        margin-left: 0px;
      }
    }
    .table-box {
      margin: 20px;
      overflow-x: scroll;
    }
  }

  .search-box {
    margin-top: 20px;
    .time-box {
      .time-title {
        margin-left: 20px;
        font-size: 14px;
      }
      .input-search {
        width: 150px;
        margin-left: 10px;
      }
    }
    .button-box {
      margin-left: 20px;
    }
  }
}
</style>
